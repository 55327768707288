@font-face {
  font-family: "Dudek";
  src: url("/src/fonts/Dudek-Regular.otf") format("woff2");
}
@font-face {
  font-family: "Dudek Bold";
  src: url("/src/fonts/Dudek-Bold.otf") format("woff2");
}
body {
  margin: 0;
  font-family: "Dudek", "Dudek Bold", "Open Sans", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: gray !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
