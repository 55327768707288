.about-title {
  text-align: left;
  font-size: 60px;
  margin-bottom: 20px;
  line-height: 100%;
  color: #123d6b;
  font-family: "Dudek Bold";
}

.about-description {
  margin-bottom: 10px;
  font-size: 16px;
  text-align: justify;
  padding-right: 145px;
}

.about-description-new {
  margin-bottom: 10px;
  font-size: 16px;
  text-align: justify;
}
.list-title {
  color: #747474;
  font-weight: bold;
  font-size: 17px;
}

.about-image {
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 789px) {
  .about-main {
    .about-title {
      text-align: center;
      font-size: 40px;
    }
    .about-description {
      text-align: center;
      padding: 10px;
    }
    li {
      text-align: center;
    }
  }
}

@media only screen and (max-width: 991px) {
  .about-main {
    .about-description {
      padding: 10px;
    }
  }
}
